<div class="relative">
  <!-- Prefix Icon -->
  <ng-container *ngIf="prefix">
    <span class="absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer">
      <ng-container *ngTemplateOutlet="prefix"></ng-container>
    </span>
  </ng-container>

  <input
    type="text"
    [placeholder]="placeholder | translate"
    [(ngModel)]="value"
    (input)="onInput($event)"
    [disabled]="disabled"
    class="w-full h-12 shadow p-3 text-base text-black rounded-xl outline-none"
    [ngClass]="{
      'border-red-500': isError,
      'border-gray-300': !isError && !disabled,
      'bg-gray-100': disabled
    }"
  />

  <!-- Suffix Icon -->
  <ng-container *ngIf="suffix">
    <span class="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer">
      <ng-container *ngTemplateOutlet="suffix"></ng-container>
    </span>
  </ng-container>

  <!-- Dropdown Suggestions -->
  <ul *ngIf="showDropdown" class="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-md mt-1">
    <li *ngFor="let suggestion of suggestions"
        class="p-2 cursor-pointer hover:bg-gray-100"
        (click)="selectSuggestion(suggestion)">
      {{ suggestion.place_name }}
    </li>
  </ul>
</div>
